<template>
    <div class="d-flex cursor-pointer hover-bg-light">
        <div class="flex-shrink-0">
            <div class="bg-light p-3 border rounded-circle text-muted">
                <font-awesome-icon :icon="faHandHoldingHeart" style="font-size: 30px;" />
            </div>
        </div>
        <div class="flex-grow-1 ms-3">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    {{invitado.NOMBRE_INVITADO}} 
                    <br>
                    <a :href="'mailto:'+invitado.CORREO_INVITADO" class="text-decoration-none me-2" v-on:click.stop="''">{{invitado.CORREO_INVITADO}}</a>
                    <a class="badge bg-primary text-white me-3 text-decoration-none" :href="'tel:'+invitado.TELEFONO_INVITADO" v-if="invitado.TELEFONO_INVITADO" v-on:click.stop="''">
                        {{invitado.TELEFONO_INVITADO}}
                    </a>
                </div>
                <button type="button" class="btn btn-outline-danger" v-on:click.stop="deleteInvitados" :disabled="loading">
                    <BtnLoading v-if="loading" text="" />
                    <span v-else>
                        <font-awesome-icon :icon="faTrash" /> Eliminar
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import BtnLoading from '@/components/BtnLoading.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHandHoldingHeart, faTrash } from '@fortawesome/free-solid-svg-icons'
export default {
    props: ['data'],
    components: {
        BtnLoading,
        FontAwesomeIcon
    },
    data(){
        return {
            faHandHoldingHeart,
            faTrash,
            loading: false
        }
    },
    methods: {
        deleteInvitados(){
            if(!this.invitado.CODIGO_INVITADO) {return}
            this.loading = true
            this.$store.dispatch('deleteInvitados', {
                id: this.invitado.CODIGO_INVITADO
            }).then(res => {
                this.$toast.success(`${this.invitado.NOMBRE_INVITADO} ha sido eliminado del listado`)
                return res
            }).catch(err => {
                if(err.response){
                    this.$toast.error(`Error al eliminar a ${this.invitado.NOMBRE_INVITADO}`)
                }
            }).then(() => {
                this.loading = false
            })
        }
    },
    computed: {
        invitado(){
            return this.data || {}
        }
    }
}
</script>