<template>
    <div class="row">
        <transition 
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInLeft"
        leave-active-class="animate__animated animate__fadeOutLeft">
            <div class="col-md-4 mb-3" v-show="create">
                <div class="card bg-light border-0 shadow">
                    <div class="card-body">
                        <form v-on:submit.prevent="submit">
                            <p class="text-muted">Ingrese información del invitado</p>
                            <div class="mb-3">
                                <label for="nombre" class="form-label">Nombre</label>
                                <input id="nombre" type="text" v-model="form.NOMBRE_INVITADO" class="form-control" placeholder="Ingrese nombre" max="150" required :disabled="loading">
                                <span v-if="errors.NOMBRE_INVITADO && errors.NOMBRE_INVITADO.length>0" class="text-danger">
                                    {{errors.NOMBRE_INVITADO[0]}}
                                </span>
                            </div>
                            <div class="mb-3">
                                <label for="correo" class="form-label">Correo electrónico</label>
                                <input id="correo" type="email" v-model="form.CORREO_INVITADO" class="form-control" placeholder="Ingrese correo electrónico" max="150" required :disabled="loading">
                                <span v-if="errors.CORREO_INVITADO && errors.CORREO_INVITADO.length>0" class="text-danger">
                                    {{errors.CORREO_INVITADO[0]}}
                                </span>
                            </div>
                            <div class="mb-3">
                                <label for="telefono" class="form-label">Teléfono</label>
                                <input id="telefono" type="tel" v-model="form.TELEFONO_INVITADO" class="form-control" placeholder="Ingrese Teléfono" max="150" :disabled="loading">
                                <span v-if="errors.TELEFONO_INVITADO && errors.TELEFONO_INVITADO.length>0" class="text-danger">
                                    {{errors.TELEFONO_INVITADO[0]}}
                                </span>
                            </div>
                            <div class="mb-3 text-end">
                                <button type="submit" class="btn btn-primary" :disabled="loading">
                                    <BtnLoading v-if="loading" :text="form.id>0 ? 'Guardando...' : 'Agregando...'" />
                                    <span v-else>
                                        <font-awesome-icon :icon="faSave" class="me-1" /> 
                                        <span v-if="form.id>0">Actualizar</span>
                                        <span v-else>Guardar</span>
                                    </span>
                                </button>
                                <button type="button" class="btn btn-link" v-on:click="cancel()" :disabled="loading">
                                    Cancelar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </transition>
        <transition 
            name="custom-classes-transition"
            enter-active-class="animate__animated animate__fadeInLeft"
            leave-active-class="animate__animated animate__fadeOutLeft">
            <div :class="{'col':true}">
                <div class="mb-3">
                    <button class="btn btn-outline-primary" v-on:click="addInvitado()">
                        <font-awesome-icon :icon="faPlusCircle" />
                        Agregar invitado
                    </button>
                </div>
                <div v-if="invitadosFiltered.length>0">
                    <InvitadosList :data="invitado" v-for="(invitado, index) in invitadosFiltered" :key="index" class="p-3 border-bottom" v-on:click="editInvitado(invitado)"/>
                </div>
                <div class="text-center text-muted" v-else>
                    No hay productos en la mesa
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import BtnLoading from '@/components/BtnLoading.vue'
import InvitadosList from '@/components/bodas/InvitadosList.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlusCircle, faTrash, faSave } from '@fortawesome/free-solid-svg-icons'
export default {
    components: {
        BtnLoading,
        FontAwesomeIcon,
        InvitadosList
    },
    data(){
        return {
            faPlusCircle,
            faTrash,
            faSave,
            create: false,
            search: '',
            loadingInvitados: false,
            loading: false,
            form: {
                CODIGO_BODA: 0,
                id: 0,
                NOMBRE_INVITADO: '',
                CORREO_INVITADO: '',
                TELEFONO_INVITADO: ''
            }
        }
    },
    mounted(){
        this.getInvitados()
    },
    methods: {
        getInvitados(){
            if (!this.boda.CODIGO_BODA) {
                return
            }
            this.loadingInvitados = true
            this.$store.dispatch('getInvitados', {
                CODIGO_BODA: this.boda.CODIGO_BODA
            }).then((res) => {
                return res
            }).catch(err => {
                //this.$toast.error('Intenta nuevamente')
                return err
            }).then(() => {
                this.loadingInvitados = false
            })
        },
        submit(){
            if(this.form.id>0){
                this.updateInvitados()
            }else{
                this.storeInvitados()
            }
        },
        storeInvitados(){
            this.loading = true
            this.form.CODIGO_BODA = this.boda.CODIGO_BODA
            this.$store.dispatch('addInvitados', this.form).then(res => {
                this.$toast.success(`${this.form.NOMBRE_INVITADO} ha sido agregado`)
                this.getInvitados()
                this.clean()
                return res
            }).catch(err => {
                if(err.response){
                    this.$toast.error(`Error al agregar a ${this.form.NOMBRE_INVITADO}`)
                }
            }).then(() => {
                this.loading = false
            })
        },
        updateInvitados(){
            if(this.form.id<1){return}
            this.loading = true
            //this.form.CODIGO_BODA = this.boda.CODIGO_BODA
            this.$store.dispatch('updateInvitados', this.form).then(res => {
                this.$toast.success(`${this.form.NOMBRE_INVITADO} ha sido actualizado`)
                this.getInvitados()
                this.clean()
                return res
            }).catch(err => {
                if(err.response){
                    this.$toast.error(`Error al actualizar a ${this.form.NOMBRE_INVITADO}`)
                }
            }).then(() => {
                this.loading = false
            })
        },
        addInvitado(){
            this.clean()
            this.create = true
        },
        editInvitado(val){
            this.create = true
            this.form.CODIGO_BODA = val.CODIGO_BODA
            this.form.id = val.CODIGO_INVITADO
            this.form.NOMBRE_INVITADO = val.NOMBRE_INVITADO
            this.form.CORREO_INVITADO = val.CORREO_INVITADO
            this.form.TELEFONO_INVITADO = val.TELEFONO_INVITADO
        },
        clean(){
            this.form.CODIGO_BODA = 0
            this.form.id = 0
            this.form.NOMBRE_INVITADO = ''
            this.form.CORREO_INVITADO = ''
            this.form.TELEFONO_INVITADO = ''
        },
        cancel(){
            this.form.id = 0
            this.create = false
        }
    },
    computed:{
        invitados() {
            return this.$store.getters.invitados || {}
        },
        errors() {
            return this.$store.getters.errors || {}
        },
        boda() {
            return this.$store.getters.boda || {}
        },
        invitadosFiltered() {
            return this.invitados.data || []
        },
        codigoBoda(){
            return this.boda.CODIGO_BODA
        }
    },
    watch: {
        codigoBoda(val){
            this.getInvitados()
            return val
        }
    }
}
</script>